import { useStaticQuery, graphql } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import styled from 'styled-components';

import utils from '../utils/utils';

const PaginationContainer = styled.div`
  background: var(--black);
  border: solid 2px var(--black);
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 8px 16px;
  display: flex;
  flex-wrap: wrap;
`;

const PaginationItem = styled.div`
  color: var(--white);
  line-height: 1;
  a {
    display: block;
    color: var(--white);
    text-decoration: none;
    transition: all 0.2s;
    background: var(--black);
    padding: 4px;
    &.active {
      transform: rotate(-3deg);
      background: var(--pink);
    }
  }
  [disabled],
  p {
    pointer-events: none;
    color: grey;
    margin: 0;
    padding: 4px;
  }
`;

export default function Pagination({ base, currentPage }) {
  const { artists } = useStaticQuery(graphql`
    query {
      artists: allSanityArtist {
        nodes {
          artistName
        }
      }
    }
  `);
  const letters = artists.nodes
    .map((artist) => artist.artistName.charAt(0))
    .sort();
  const uniqueLetters = ['All', ...new Set(letters)];
  /**
   * Create navigation links for actual pages and use <p> element as a fallback to avoid gatsby trying to prefetch a page that doesn't exist
   * @param {string} letter
   */
  const createNavigationItems = (letter) => {
    const paginationLink = letter === 'All' ? `${base}` : `${base}/${letter}`;
    return !uniqueLetters.includes(letter) ? (
      <p>{letter}</p>
    ) : (
      <Link
        title={`Bands startting with the letter ${letter}`}
        to={paginationLink}
        disabled={!uniqueLetters.includes(letter)}
        className={letter === currentPage ? 'active' : ''}
      >{`${letter}`}</Link>
    );
  };

  return (
    <PaginationContainer>
      {utils.alphabet.map((letter, idx) => (
        <PaginationItem key={`${letter}-${idx}`}>
          {createNavigationItems(letter)}
        </PaginationItem>
      ))}
    </PaginationContainer>
  );
}
