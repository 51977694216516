import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';

const ArtistStyles = styled.div`
  display: flex;
  background: white;
  padding: 1.5rem;
  margin: 0 auto 32px auto;
  border: solid 2px var(--black);
  box-shadow: 5px 5px 0 0 rgba(0, 0, 0, 0.6);
`;

const ArtistTitle = styled.div`
  border-bottom: dotted 2px;
  padding-bottom: 10px;
  flex: 2;
`;

const ArtistImage = styled.div`
  margin-right: 16px;
  width: 150px;
`;

export default function ArtistCard({ name, description, image }) {
  return (
    <ArtistStyles>
      {image && (
        <ArtistImage>
          <Img fluid={image?.asset?.fluid} />
        </ArtistImage>
      )}
      <ArtistTitle>
        <h2>{name}</h2>
        <p>{description}</p>
      </ArtistTitle>
    </ArtistStyles>
  );
}
