import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-intl';
import styled from 'styled-components';
import ArtistCard from '../components/ArtistCard';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';

const ArtistLink = styled.div`
  transition: all 0.3s;
  &:hover {
    transform: scale(1.025);
  }
  a:hover {
    color: var(--pink);
  }
  @media (prefers-reduced-motion) {
    &:hover {
      transform: scale(1.025);
    }
  }
`;
const List = styled.div`
  max-width: var(--page-width);
  width: 100%;
`;

export default function ArtistsPage({ data, pageContext }) {
  const paginationData = data.artists.nodes;
  return (
    <>
      <SEO title="All artists" />
      <List>
        <Pagination
          base="/artists"
          currentPage={pageContext.currentPage || 'All'}
          allData={paginationData}
        />
        {data.artists.nodes.map((artist) => (
          <ArtistLink key={artist.id}>
            <Link to={`/artists/${artist.slug.current}`}>
              <ArtistCard
                name={artist.artistName}
                description={artist.description}
                key={artist.id}
                image={artist.artistImage}
              />
            </Link>
          </ArtistLink>
        ))}
      </List>
    </>
  );
}

export const query = graphql`
  query($filter: String = "/^/") {
    artists: allSanityArtist(filter: { artistName: { regex: $filter } }) {
      nodes {
        artistName
        id
        description
        slug {
          current
        }
        artistImage {
          asset {
            fluid(maxWidth: 1000, maxHeight: 750) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
